import React, { useRef } from "react"
import useBoxSize from "../../../hooks/useBoxSize"
import useWindowSize from "../../../hooks/useWindowSize"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import CommonLink from "../CommonLink"

const LuxuryRoom = ({
  data,
  index,
  boxClass,
  length,
  btnNotrequired,
  gatsbyImage,
  featureImage,
  luxButton,
  luxDescription,
  luxMeetImage,
  luxTitle,
  stateParkImage,
}) => {
  const { width } = useWindowSize()
  const boxRef = useRef()
  const swidth = useBoxSize({ boxRef: boxRef })
  return (
    <div
      className={`luxury_block ${index === length ? "pets" : boxClass}`}
      key={index}
    >
      <div className="row align-items-center">
        <div className={index % 2 === 0 ? "col-lg-6 order-2" : "col-lg-6"}>
          <div
            className="luxury_thumb"
            data-aos="fade-up"
            data-aos-delay={`${50 + (index - 1) * 50}`}
            data-aos-duration={`${1000 + (index - 1) * 100}`}
            ref={boxRef}
          >
            {gatsbyImage ? (
              <a href={"#"}>
                <ImageWrapper
                  width={swidth}
                  height={0.69 * swidth}
                  image={data?.thumbnail?.asset?.gatsbyImageData}
                  alt="Pet Map"
                  objectFit="cover"
                  className={"pet__map__bg"}
                ></ImageWrapper>
              </a>
            ) : featureImage ? (
              <CommonLink
                link={data?.meetLink?.path ? data.meetLink.path : "#"}
                type={data.meetLink.type}
              >
                <ImageWrapper
                  width={swidth}
                  height={
                    width > 992 && width < 1200 ? 0.9 * swidth : 0.8 * swidth
                  }
                  image={data?.thumbnail?.asset?.gatsbyImageData}
                  alt="Pet Map"
                  objectFit="cover"
                  className={"pet__map__bg"}
                ></ImageWrapper>
              </CommonLink>
            ) : luxMeetImage ? (
              <CommonLink
                link={luxButton?.path ? luxButton.path : "#"}
                type={luxButton.type}
              >
                <ImageWrapper
                  width={swidth}
                  height={
                    width > 767 && width < 992 ? 0.69 * swidth : 0.8 * swidth
                  }
                  image={luxMeetImage.asset.gatsbyImageData}
                  alt="Pet Map"
                  objectFit="cover"
                  className={"pet__map__bg"}
                ></ImageWrapper>
              </CommonLink>
            ) : stateParkImage ? (
              <>
                <CommonLink
                  link={data?.button?.path ? data.button.path : "#"}
                  type={data?.button?.type}
                >
                  <ImageWrapper
                    width={swidth}
                    height={
                      width > 767 && width < 992 ? 0.69 * swidth : 0.75 * swidth
                    }
                    image={data?.thumbnail?.asset.gatsbyImageData}
                    alt="Pet Map"
                    objectFit="cover"
                    className={"pet__map__bg"}
                  ></ImageWrapper>
                </CommonLink>
              </>
            ) : (
              <CommonLink
                link={data?.button?.path ? data.button.path : "#"}
                type={data.button.type}
              >
                <img
                  src={data.featureImage ? data.featureImage : data.image}
                  alt=""
                />
              </CommonLink>
            )}
          </div>
        </div>
        <div className={index % 2 === 0 ? "col-lg-6 order-1" : "col-lg-6"}>
          <div
            className="luxury_content_blk"
            data-aos="fade-up"
            data-aos-delay={`${50 + (index - 1) * 50}`}
            data-aos-duration={`${1000 + (index - 1) * 100}`}
          >
            <h4>{luxTitle ? luxTitle : data.title}</h4>
            <p>{luxDescription ? luxDescription : data.description}</p>
            {btnNotrequired ? (
              ""
            ) : (
              <CommonLink
                className="common_btn"
                link={luxButton ? luxButton.path : data?.meetLink?.path}
              >
                {luxButton ? luxButton.label : data?.meetLink?.label}
              </CommonLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LuxuryRoom
const ImageWrapper = styled(GatsbyImage)`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-repeat: no-repeat;
  margin-top: 20px;
`
