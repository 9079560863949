import * as React from "react"
import Layout from "../../components/Layout"
import HeroBanner from "../../components/HeroBanner"
import BookingOnline from "../../components/BookingOnline"
import InvitingRoomSlider from "../../components/Sliders/InvitingRoomSlider"
import LuxuryRoom from "../../components/luxuryRoom"
// import Instagram from "../components/Instagram"
import Aos from "aos"
import BackToTop from "../../components/BackToTop"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import BookRoomSlider from "../../components/Sliders/BookRoomSlider"
import styled from "styled-components"
import CommonLink from "../../components/CommonLink"

const IndexPage = ({ data }) => {
  React.useEffect(() => {
    Aos.init()
  }, [])
  const {
    heroImg,
    luxuryMeet,
    bookRoom,
    welcomeSection,
    hotelEnvironmentSection,
    invitingGuestRoomSection,
  } = data?.sanityPages?.homePage
  return (
    <>
      <Seo
        title={"NoyoHarborInn Home"}
        description={"This is landing page of NoyoHarborInn Website."}
      />
      <Layout>
        <HeroBanner
          bannerImg={heroImg?.asset?.gatsbyImageData}
          pageClass={"home"}
        />
        <div className="action__section">
          <div className="container">
            <BookingOnline />
          </div>
          <BackToTop />
        </div>
        <div className="luxury__section">
          <div className="container">
            <LuxuryRoom
              luxButton={luxuryMeet?.meetLink}
              luxDescription={luxuryMeet?.description}
              luxMeetImage={luxuryMeet?.thumbnail}
              luxTitle={luxuryMeet?.title}
              index={1}
            />
          </div>
        </div>
        <div className="bed__section home__page">
          <div className="container">
            <div className="row">
              <BookRoomSlider
                bookRoom={bookRoom?.images}
                bookRoomsButton={bookRoom?.meetLink}
              />
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="bed_btn text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="1300"
                >
                  <CommonLink
                    link={bookRoom?.meetLink?.path}
                    type={bookRoom?.meetLink?.type}
                  >
                    {bookRoom?.meetLink?.label}
                  </CommonLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="welcome__section"
          style={{
            backgroundImage: `url(${welcomeSection?.thumbnail?.asset?.url})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="welcome_content_blk">
                  <h3
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    {welcomeSection?.title}
                  </h3>
                  <p
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1100"
                  >
                    {welcomeSection?.description}
                  </p>
                  <CommonLink
                    data-aos="fade-up"
                    data-aos-delay="150"
                    data-aos-duration="1200"
                    link={welcomeSection?.meetLink?.path}
                    type={welcomeSection?.meetLink?.type}
                  >
                    {welcomeSection?.meetLink?.label}
                  </CommonLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="environment__area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {hotelEnvironmentSection?.hotelEnvironments?.map(
                  (item, index) => (
                    <LuxuryRoom
                      key={index}
                      featureImage
                      data={item}
                      boxClass={"luxury__enviro__item"}
                      index={index + 1}
                      length={
                        hotelEnvironmentSection?.hotelEnvironments?.length
                      }
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="inviting__area">
          <div className="container">
            <InvitingRoomSlider data={invitingGuestRoomSection} />
          </div>
        </div>
        {/* <div className="follow__section">
          <div className="container">
            <Instagram />
          </div>
        </div> */}
      </Layout>
    </>
  )
}

export default IndexPage
const ImageWrapper = styled.div`
  background: ${props => `url(${props.bannerImg})`};
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  margin-top: 20px;
`

export const homesData = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      homePage {
        heroImg {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        luxuryMeet {
          title
          description
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          meetLink {
            label
            path
            type
          }
        }
        bookRoom {
          images {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          meetLink {
            type
            path
            label
          }
        }
        welcomeSection {
          title
          description
          thumbnail {
            asset {
              url
            }
          }
          meetLink {
            label
            path
            type
          }
        }
        hotelEnvironmentSection {
          hotelEnvironments {
            title
            description
            thumbnail {
              asset {
                gatsbyImageData(fit: FILLMAX, formats: WEBP)
              }
            }
            meetLink {
              label
              path
              type
            }
          }
        }
        invitingGuestRoomSection {
          title
          images {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          meetLink {
            label
            path
            type
          }
        }
      }
    }
  }
`
