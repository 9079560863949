import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CommonLink from "../../CommonLink"

const InvitingRoomSlider = ({ data }) => {
  const RightArrow = props => {
    return (
      <div {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="29"
          viewBox="0 0 17 29"
          fill="none"
        >
          <path d="M1 1L15 14.5L1 28" stroke="#fff" stroke-width="2" />
        </svg>
      </div>
    )
  }
  const sliderdata = useStaticQuery(graphql`
    query InvitingQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "invitingGuestRoom" } } }
      ) {
        edges {
          node {
            frontmatter {
              bookButton {
                url
                title
              }
              rooms {
                roomsImage {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
              }
              title
            }
          }
        }
      }
    }
  `)
  const { images, title, meetLink } = data
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="inviting_title">
            <h3 data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
              {title}
            </h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="inviting_slider_active owl-carousel">
            <Slider {...settings} nextArrow={<RightArrow />}>
              {images.map((item, index) => (
                <div
                  className="inviting_single_items px-2"
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={`${100 + index * 50}`}
                  data-aos-duration={`${1100 + index * 100}`}
                >
                  <GatsbyImage
                    image={item?.asset?.gatsbyImageData}
                    alt="Inviting Room"
                    objectFit="cover"
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div
            className="action_btn mt-5"
            style={{ textAlign: "center" }}
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <CommonLink link={meetLink?.path} type={meetLink?.type}>
              {meetLink?.label}
            </CommonLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvitingRoomSlider
