import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useWindowSize from "../../../../hooks/useWindowSize"
import { GatsbyImage } from "gatsby-plugin-image"
import CommonLink from "../../CommonLink"

const BookRoomSlider = ({ bookRoom, bookRoomsButton }) => {
  const { width } = useWindowSize()
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <>
      {bookRoom.length <= 3 && width > 992 ? (
        bookRoom.map((item, index) => (
          <div className="col-lg-4 px-2" key={index}>
            <div
              className="bed_thumb"
              data-aos="fade-up"
              data-aos-delay={`${50 + index * 50}`}
              data-aos-duration={`${1000 + index * 100}`}
            >
              <CommonLink
                link={bookRoomsButton?.path}
                type={bookRoomsButton?.type}
              >
                <GatsbyImage
                  image={item?.asset?.gatsbyImageData}
                  alt="Bed Thumb"
                />
              </CommonLink>
            </div>
          </div>
        ))
      ) : (
        <Slider {...settings}>
          {bookRoom.map((item, index) => (
            <div className="col-lg-4 px-2" key={index}>
              <div
                className="bed_thumb"
                data-aos="fade-up"
                data-aos-delay={`${50 + index * 50}`}
                data-aos-duration={`${1000 + index * 100}`}
              >
                <CommonLink
                  link={bookRoomsButton?.path}
                  type={bookRoomsButton?.type}
                >
                  <GatsbyImage
                    image={item?.asset?.gatsbyImageData}
                    alt="Bed Thumb"
                  />
                </CommonLink>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </>
  )
}

export default BookRoomSlider
